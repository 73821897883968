import cookie from "js-cookie";
import React from 'react';
import {Navigate} from 'react-router-dom';

const AuthRoute = ({ children,  isLoggedIn}) => {
    const redirectBackUrl = cookie.get('redirect_from');
    const token = cookie.get('token');
    if(redirectBackUrl && token){
        window.location.href = redirectBackUrl+"?token="+token;
    }
    return  isLoggedIn || (cookie.get('is_logged_in') === '1') ? children : <Navigate to={"/Login"} />;
};


export default AuthRoute;