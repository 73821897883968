import React from 'react';
import {Box, Container} from '@mui/material';
import { useTranslation } from 'react-i18next';


export default function Home() {
  const { t } = useTranslation();

  return (  
    <Container maxWidth="xl" className="content-wrapper">
        <>
        <Box>{t("Hello customer!")}</Box>
      </>
    </Container>
  );
}
